<template>
<div>

<!-- notification -->
<v-snackbar v-model="notificationToggle" :color="notificationType" :timeout="5000" :top="true"> {{ notificationMsg }}
  <v-btn dark text @click="notificationToggle = false" > Close </v-btn>
</v-snackbar>

<section id="ContactUs" class="ma-0 mx-auto py-5 primary" style="color: #ddd" >
<v-container>
<div class="titleDiv px-5" style="border-left: 10px solid #ddd;">
  <p class="display-1">Contact Us</p>
</div>
<div class="py-5">

  <v-row>

    <v-col cols="12" sm="12" md="6" order="2" order-sm="1" class="mb-5 address">

      <v-container class="mt-0 pt-0">
      <p class="headline mb-1">Address</p><hr width="20%">

      <div style="padding-top: 1.5rem">
        <p class="headline mb-1">Kalalaya Academy</p>
        <p class="subtitle-2">
          Puliyara Keerthi Veetil, <br>
          Kayarampara – KV road, <br>
          Palappuram, Ottapalam – 679103 <br>
          Mobile: +91 6238758661 / +91 9791101509 / +91 9003207575 <br>
          Email: Kalalaya.Academy2019@gmail.com <br>
          Office Hours: Always Open <br>
        </p>
      </div>
      </v-container>

      <v-container class="">
      <p class="headline mb-1">Get Updates On</p><hr width="30%">

      <div style="padding-top: 1.5rem">
      <div class="d-flex align-center">

        <a href="https://instagram.com/kalalaya_academy?igshid=rhwoss8hokdk">
          <div class="SocialIcons d-flex justify-center align-center">
            <p class="subtitle-2 ma-0"><v-icon>mdi-instagram</v-icon></p>
          </div>
        </a>

        <a href="https://www.facebook.com/pages/category/Education/Kalalaya-Academy-100118604819166/">
        <div class="SocialIcons ml-5 d-flex justify-center align-center">
          <p class="subtitle-2 ma-0"><v-icon>mdi-facebook</v-icon></p>
        </div>
        </a>

        <a href="https://www.youtube.com">
        <div class="SocialIcons ml-5 d-flex justify-center align-center">
          <p class="subtitle-2 ma-0"><v-icon>mdi-youtube</v-icon></p>
        </div>
        </a>

      </div>  
      </div>
      </v-container>
      

    </v-col>

    <v-col cols="12" sm="12" md="6" order="1" order-sm="2" class="mb-5">
    <v-card class="py-5 elevation-5">
      <v-card-title><p class="display-1">Contact</p></v-card-title>
      <v-card-text>
      <form @submit.prevent="Submit()">
          <v-text-field label="Name" :error="$v.Name.$error" :error-messages="NameErrors" 
            v-model.trim="Name" @input="$v.Name.$touch()" required ></v-text-field>   

          <v-text-field type="email" label="Email" :error="$v.Email.$error" :error-messages="EmailErrors"
            v-model.trim="Email" @input="$v.Email.$touch()" required ></v-text-field>    

          <v-textarea label="Description" v-model="Description" :error="$v.Description.$error" 
          :error-messages="DescriptionErrors" @input="$v.Description.$touch()" required></v-textarea>

          <div class="d-flex align-center justify-center py-5">
            <v-btn type="submit" fab bottom absolute class="accent elevation-10">
              <v-icon :class="{'mdi-spin': disbtn}">mdi-heart</v-icon>
            </v-btn>
          </div>
      </form>
      </v-card-text>
    </v-card>
    </v-col>

  </v-row>
  
</div>
</v-container>

</section>

</div>
</template>

<script>
// imports
import { required, email } from 'vuelidate/lib/validators';
import firebase from 'firebase/app';
import "firebase/functions";

// scripts
export default {

    name: 'contactus',

    data(){return{
        // app flags
        notificationToggle: false,notificationMsg: 'hai',notificationType: 'hui',disbtn: false,
        // extra
        Name: '', Email: '',Description: '',
    }},

    computed: {
        // basically in computed, i added validations  
        // sorry, i cant find a way to make this even smaller : -D
        NameErrors () { return this.ErrorPusher(this.$v.Name.$dirty, this.$v.Name.required, 'Name is Required'); },
        EmailErrors () { return this.ErrorPusher(this.$v.Email.$dirty, this.$v.Email.email, 'A Valid Email is Required'); },
        DescriptionErrors () { return this.ErrorPusher(this.$v.Description.$dirty, this.$v.Description.required, 'Description is Required'); },
    },

    methods: {

        Submit(){

            if(!this.BlankValidation()){return false;}    
            let ename = this.Name;
            this.disbtn = true; 
            let contentMsg = `
                            <h1>You Got A New Feedback For Kalalaya Academy Website</h1>
                            <p>Name: ${ename}</p>
                            <p>Email: ${this.Email}</p>
                            <p>Description : </p>
                            <p>${this.Description}</p>
                          `;
            
            let SendMail =  firebase.functions().httpsCallable('sendMail');
            SendMail({subject: 'Application Feedback', content: contentMsg}).then((per) => {
                this.notificationService("Thank You, We Appreciate It!", "info");
                this.disbtn = false;this.ResetFields();
            }).catch((err) => {console.log(err)});
          
        },

        // reset fields
        ResetFields(){     
            this.Name = '';this.Email = '';this.Description = '';
            this.$v.$reset();
        },

        // check if all fileds are empty   
        BlankValidation(){
            if(this.Name == '' || this.Email == '' || this.Description == '')
            {this.notificationService("Please Fill All Fields!", "red darken-1"); return false;}else{return true;}
        },

        // error pusher for vuelidate   
        ErrorPusher(basic, extra, msg){
            const errors = []
            if (!basic) return errors
            !extra && errors.push(msg);
            return errors;
        },

        // notification service
        notificationService(msg, type){
            this.notificationType = type;
            this.notificationMsg = msg;
            this.notificationToggle = true;
        },
    },

    // validations
    validations: {
        Name: { required }, 
        Email: { required, email },
        Description: { required },
    },
}
</script>

<style scoped>
#ContactUs{
  margin-top: 0;
  padding-top: 7rem !important;
}
#ContactUs .SocialIcons{
  border: 5px solid #eee;
  border-radius: 100px;
  height: 60px;
  width: 60px;
}
#ContactUs .SocialIcons .v-icon{ color:#eee }
@media (max-width: 900px) {
  #ContactUs .address{
    padding-top: 5rem;
  }
}
</style>